<template>
  <div>
    <div class="user">
      <div style="position: relative; width:100%; ">
        <div id="top" class="topv">
          <top-view ref="toptitle" @changeLang="changeLang" @selectTable="selectTable" @menu="userMenuHandler"></top-view>
        </div>
        <img ondragstart="return false" style="position: absolute;top: 82px;left: 0px;width: 100%;" src="../assets/art/背景_01.jpg" alt="">

        <div v-if="obj" class="cardtab" style="min-width: 1280px;">
          <div class="cardtab-header">
            <img ondragstart="return false" style="width: 1280px;position: relative;" src="../assets/art/别表头.png" alt="">
            <div style="position: absolute; top: 15px;left: 39px;font-size: 24px;font-weight: bold;color: #E45700;">{{$t('detail.xq') }}
            </div>
          </div>
          <div class="cardtab-content1">
            <div class="c-left">
              <div class="c-left-head">
                <img ondragstart="return false" class="c-left-img" :src="obj.authorHeadImg" alt="">
                <div class="c-left-name">{{ obj.authorName }}</div>
                <div v-if="obj.hasOther" class="c-left-more" @click="moreObject()">{{$t('detail.gdzp') }}</div>
              </div>
              <div class="c-lineplate" style="margin-top: 24px;">
                <div class="c-lineplate-text">{{$t('detail.mc') }}：</div>
                <div class="c-lineplate-value">{{ obj.worksName }}</div>
              </div>
              <div class="c-lineplate">
                <div class="c-lineplate-text">{{$t('detail.lm') }}：</div>
                <div class="c-lineplate-value">{{ lang?obj.categoryNameEn: obj.categoryName }}</div>
              </div>
              <div class="c-lineplate">
                <div class="c-lineplate-text">{{$t('detail.sj') }}：</div>
                <div class="c-lineplate-value" style="font-size: 12px;">{{ obj.updateTime }}</div>
              </div>
              <div class="c-lineplate">
                <div class="c-lineplate-text">{{$t('detail.ps') }}：</div>
                <div class="c-lineplate-value">{{ obj.ticketNum }}</div>
              </div>
              <div class="c-lineplate">
                <div class="c-lineplate-text">{{$t('detail.pm') }}：</div>
                <div class="c-lineplate-value">{{ obj.categorySort }}</div>
              </div>
              <div class="c-lineplate">
                <div class="c-lineplate-text">{{$t('detail.zpm') }}：</div>
                <div class="c-lineplate-value">{{ obj.totalSort }}</div>
              </div>

              <div v-if="obj.ticketDays > 2" class="c-num">{{$t('detail.tpts') }}{{ obj.ticketDays }}{{$t('detail.day') }} </div>
              <div v-if="status==0" class="c-btn-view">
                <div class="c-btn" style="margin-left: 8px;" :style="{ backgroundColor: obj.collect ? '#666' : '' }"
                  @click="shouchangHandler()">
                  <img ondragstart="return false" class="c-btn-img" src="../assets/art/收藏icon@2x.png" alt="">
                  <div class="c-btn-text">{{ obj.collect ? $t('detail.qxsc') : $t('detail.sc') }}</div>
                </div>

                <div class="c-btn" style="margin-left: 8px;" :style="{ backgroundColor: obj.vote ? '' : '#999' }"
                  @click="voteHandler()">
                  <img ondragstart="return false" class="c-btn-img" src="../assets/art/投票.png" alt="">
                  <div class="c-btn-text">{{ obj.vote ? $t('detail.tp') : $t('detail.ytp') }}</div>
                </div>
              </div>

              <!-- 我的作品按钮 -->
              <div v-if="status==1" class="c-btn-view">
                <div class="c-btn" style="margin-left: 8px;" :style="{ backgroundColor: '#E45700' }"
                  @click="removeItem()">
                  <div class="c-btn-text">{{$t('detail.sczp')}}</div>
                </div>

                <div class="c-btn" style="margin-left: 8px;" :style="{ backgroundColor: '#E45700'}"
                  @click="editItem()">
                  <div class="c-btn-text">{{ $t('detail.bjzp') }}</div>
                </div>
              </div>
            </div>

            <div class="c-plate1">
              <div class="c-title">{{ $t('detail.js') }}：</div>
              <div class="c-message" style="white-space: pre-wrap;">
                {{ obj.worksIntroduces }}
              </div>
              <div v-if="obj.videos" class="c-video">
                <video controls style="width: 100%;height: 100%;" :src="obj.videos"></video>
              </div>

              <img ondragstart="return false" v-if="obj.coverImg" class="c-img" :src="obj.coverImg" alt="">
              <div class="c-img" v-for="(item, index) in obj.imgs" :key="index">
                <img ondragstart="return false" style="width: 100%;height: 100%;" :src="item" alt="">
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="toupiao" @click="jumptoart()" >
        <div class="toupiaotext">{{$t('homePage.jrkt') +' '+ voteNum}}/3</div>
        <div class="toupiaobtn">{{$t('homePage.tp') }}</div>
      </div>
      <div class="canshai" @click="jumptojoin()">
        <div class="toupiaotext">{{$t('homePage.sczp') }}</div>
        <div class="toupiaobtn">{{$t('homePage.sc') }}</div>
      </div>

      <el-backtop :right="100" :bottom="100" style="background-color: rgba(0, 0, 0, 0.01);">
      <img ondragstart="return false" style="width: 50px;" src="../assets/home/top.png" alt="">
    </el-backtop>
    </div>
    <el-backtop :right="100" :bottom="100" style="background-color: rgba(0, 0, 0, 0.01);">
      <img ondragstart="return false" style="width: 50px;"  src="../assets/home/top.png" alt="">
    </el-backtop>
  </div>
</template>
  
<script>
// @ is an alias to /src

import * as API_URL from '../api/url'
import topView from './components/homeTop.vue'
import {managerUrl} from '@/libs/axios.js'
// import debounce from '@/libs/debounce'
// import * as API_URL from '../api/url'
// import { upload, uploadByPieces } from './js/upload.js'
// import { Loading } from "element-ui";

export default {
  name: 'art',
  components: {
    topView,
  },
  data() {
    return {
      lang: false,
      options: [],
      obj: null,
      code: '',
      voteNum: 3,
      status:0,

    }
  },

  computed: {
    // subzonev(newValue,oldValue){
    //   return '+'+this.subzonevalue
    // }
  },
  methods: {
    selectTable(e) {
      console.log(e)
      this.$router.push({
        name: 'Home',
        params: {
          index: e
        }
      })
    },

    changeLang() {
      let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
      if (isZh === 'zh') {
        localStorage.setItem('lang', 'en'); // en表示英文，zh表示中文，可根据自己喜好设定，尽量通俗易懂
        this.$i18n.locale = 'en'
        this.lang = true
      } else {
        localStorage.setItem('lang', 'zh');
        this.$i18n.locale = 'zh'
        this.lang = false
      }
      window.location.reload()
    },

    // 参赛
    jumptojoin() {
      this.$router.push('/join')
    },

    jumptoart() {
      this.$router.push('/art')
    },

    removeItem() {
        // this.$emit('remove')
        API_URL.removeWork({ worksId: this.obj.worksId }).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.message)
          setTimeout(() => {
            // this.$router.push('/user')
            window.close()
          }, 500);
        }
      });

    },

    editItem(){
      // this.$emit('edit')
      this.$router.push({
        name:'joinEdit',
        params: {
          worksId:this.obj.worksId,
        }
      })
    },

    userMenuHandler(e) {
      console.log(e)
      if (e < 6) {
        this.$router.push(
          {
            name: 'user',
            params: {
              index: e
            }
          }
        )
      } else {
        localStorage.setItem('userData', '')
        localStorage.setItem('accessToken', '')
        this.$router.push({
          name: 'Home',
        })
      }

    },

    shouchangHandler() {
      if (!this.obj.collect) {
        //收藏
        console.log('点击收藏')
        API_URL.addCollect({ worksId: this.code }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message)
            this.getData(this.code)
          } else {
            this.$message.success(res.message)
          }
        })
      } else {
        // 取消收藏
        API_URL.cancelCollect({ worksId: this.code }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message)
            this.getData(this.code)
          } else {
            this.$message.success(res.message)
          }
        })
      }
    },

    voteHandler() {
      if (this.obj.vote) {
        //投票
        console.log('投票')
        API_URL.vote({ worksId: this.code }).then((res) => {
          if (res.code == 0) {
            this.$message.success(res.message)
            this.getData(this.code)
          } else {
            this.$message.success(res.message)
          }
        })
      } else {
        console.log('已投票')
      }

    },

    moreObject() {
      this.$router.push({
        name: 'art',
        params: {
          name: this.obj.authorName,
          userId:this.obj.userId
        }
      })
    },
    // 监听微信扫码登录 回传code
    ntfScan() {

      var url = window.location.href;             //获取当前url
      var dz_url = url.split('#')[1];                //获取#/之前的字符串
      var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
      var cs_arr = cs ? cs.split('&') : [];                    //参数字符串分割为数组
      var cs2 = {};
      for (var i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
        cs2[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      const code = cs2.id;
      this.code = code
      this.status = cs2.status
      if (code) {
        this.getData(code)
      }

    },

    getData(code) {
      API_URL.objectDetail({ worksId: code }).then((res) => {
        if (res.code == 0) {
          this.obj = res.data
          this.getVoteNum()
        }
      });
    },

    // 获取投票次数
    getVoteNum() {
      API_URL.voteNum().then((res) => {
        if (res.code == 0) {
          this.voteNum = 3 - Number(res.data)
        } else {
          this.$message.info(res.message)
        }
      });
    },


    reloadLang(){
      let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
      if (isZh === 'en') {
        this.lang = true
      } else {
        this.lang = false
      }
    }


  },

  mounted() {
    //获取屏幕尺寸

    // this.uploadURL = (process.env.NODE_ENV === 'development' ? 'https://api.haoqi.fun/java-home/competition/work/upload' : 'https://api.haoqi.fun/java-home/competition/work/upload')
    this.uploadURL = managerUrl+'/competition/work/upload'
    this.userData = JSON.parse(localStorage.getItem('userData') ? localStorage.getItem('userData') : '')
    // this.activeIndex = this.$route.params.index
    this.ntfScan()
    this.reloadLang()

  },

  watch: {
  }

}

</script>
  
<style lang="scss" scoped>
@import url('./css/artdetail.css');

.user {
  box-sizing: border-box;
  overflow-x: hidden;
  height: fit-content;
  background-color: black;
  position: relative;
  width: 100%;
  height: 100vh;
}

.topv {
  // position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
}


.testupload /deep/ .el-upload {
  display: none
}


::v-deep .customv .el-textarea__inner {
  background-color: #F2F2F2 !important;
  color: #999999 !important;
}

::v-deep .customv2 .el-input__inner {
  background-color: #F2F2F2 !important;
  color: #999999 !important;
  border: none;
}


.zh {
  margin-top: 40px;
  width: 200px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  color: #fff;
  font-size: 20px;
  margin-bottom: 40px;
  background-image: url('../assets/join/置灰按钮背景.png');
  background-size: cover;
  border: none;
  background-color: #d8d8d880;
}

.toupiao {
  position: fixed;
  right: calc(100vw * (40 / 1920));
  top: calc(100vw * (200 / 1920));
  width: calc(100vw * (212 / 1920));
  height: calc(100vw * (252 / 1920));
  font-size: 20px;
  border-radius: 12px;
  background-image: url('../assets/home/侧边-投票.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}
 .toupiaotext{
  margin-top: calc(100vw * (100 / 1920));
  font-weight: bold;
  font-size: calc(100vw * (20 / 1920));
  color: #333;
  margin-right: calc(100vw * (12 / 1920));
  margin-right: calc(100vw * (12 / 1920));
}

.toupiaobtn{
  margin-top: calc(100vw * (17 / 1920));
  margin-right: calc(100vw * (12 / 1920));
  font-weight: bold;
  font-size: calc(100vw * (32 / 1920));
  width: calc(100vw * (170 / 1920));;
  height: calc(100vw * (54 / 1920));;
  color:#fff;
  background-image: url('../assets/home/按钮.png');
  background-size: cover;
  line-height: calc(100vw * (54 / 1920));;
}

.canshai {
  position: fixed;
  right: calc(100vw * (40 / 1920));
  top: calc(100vw * (500 / 1920));
  width: calc(100vw * (212 / 1920));
  height: calc(100vw * (252 / 1920));
  background-image: url('../assets/home/侧边-参赛.png');
  background-size: cover;
  font-size: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
  