<template>
    <div class="join">
  
      <div style="position: relative; width:100%;">
        <div id="top" class="topv">
          <small-topview v-if="showSmall && !topHidden"> </small-topview>
          <top-view ref="toptitle" v-if="!showSmall && !topHidden" @changeLang="changeLang" @selectTable="selectTable"
            @menu="userMenuHandler"></top-view>
        </div>
        <img ondragstart="return false" style="position: absolute;top: 82px;left: 0px;width: 100%;" src="../assets/art/背景_01.jpg" alt="">
  
        <div class="cardtab">
          <div class="cardtab-header">
            <img ondragstart="return false" style="width: 1280px;position: relative;" src="../assets/art/别表头.png" alt="">
            <div style="position: absolute; top: 15px;left: 39px;font-size: 24px;font-weight: bold;color: #E45700;">
              {{ $t('tableHeader.sczp') }}
            </div>
          </div>
          <div class="cardtab-content ">
            <div style="display: flex;">
              <div class="content-titletext">{{ $t('upload.t1') }}</div>
              <div class="content-titletext" style="color: #E45700;margin-left: 5px;margin-right: 5px;">{{ $t('upload.t2') }}</div>
              <div class="content-titletext">{{ $t('upload.t3') }}</div>
              <div class="content-titletext" style="color: #E45700;margin-left: 5px;margin-right: 5px;">{{ $t('upload.t4') }}</div>
              <div class="content-titletext"> {{ $t('upload.t5') }}</div>
            </div>
  
  
            <img ondragstart="return false" v-if="active < 4" class="content-step" :src="require('../assets/join/' + active + '.png')" alt="">
            <div v-if="active > 1 && active < 4" class="content-titles">
              <div class="content-titles-text">{{ typeArr[selectType].title }}</div>
              <div class="content-titles-text">{{ formModel.name }}</div>
              <div class="content-titles-text"></div>
            </div>
            <div v-if="active == 1" class="content-mes">{{ $t('upload.t6') }}</div>
  
  
  
            <div v-show="active == 1" class="content-plate">
              <div class="content-plat-item" :class="{ 'content-plat-item-select': index == selectType }"
                v-for="(item, index) in typeArr" :key="index" @click="selectType = index">
                <img ondragstart="return false" class="content-plat-item-img"
                  :src="require('../assets/join/' + (index == selectType ? item.imgurlSelect : item.imgurl) + '')" alt="">
                <div class="content-plat-item-content">
                  <div class="content-plat-item-content-title">{{ item.title }}</div>
                  <div class="content-plat-item-content-mes">{{ item.message }}</div>
                </div>
                <!-- <div class="content-plat-item-righttop"></div> -->
              </div>
            </div>
  
            <div v-show="active == 2 && userData" class="upplate ">
              <div>
                <el-form label-width="150px" :label-position="'left'" :model="formModel" ref="formModelRef"
                  :rules="formModelRules">
                  <el-form-item label-width="130px" :label="$t('upload.zpmc') + '：'" prop="name">
                    <el-input @input="inputChange" class="customv2" :placeholder="$t('upload.h7')" v-model="formModel.name">
                    </el-input>
  
                  </el-form-item>
                  <el-form-item :label="$t('upload.zpfm') + '：'" prop="headImg">
                    <el-upload style="width: 100px;margin-top: 10px;" :style="{ marginLeft: headFileList[0] ? '' : '15px' }"
                      ref="uploadhead" :action="uploadURL" :file-list="headFileList" :class="{ testupload: hideUpload }"
                      list-type="picture-card" :headers="{
                        'jwtToken': userData ? userData.jwtToken : ''
                      }" :data="{
    type: 1, workNum: workNum
  }" :limit="1" name="img" :on-preview="handlePreview" :on-remove="remove" :on-change="uploadChange"
                      :before-upload="beforeAvatarUpload">
  
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <div style="width: 100%;margin-left: 12px; text-align: left;color: #999;font-size: 12px;">
                      {{ $t('upload.h1') }}</div>
                    <el-dialog :visible.sync="previewVisible">
                      <img ondragstart="return false" width="100%" :src="previewPath" alt="">
                    </el-dialog>
                  </el-form-item>
                  <el-form-item :label="$t('upload.zpsc') + '：'" prop="codeImg">
                    <el-upload style="width: 100%; margin-top: 10px;" ref="uploadcode" :headers="{
                      'jwtToken': userData ? userData.jwtToken : ''
                    }" :data="{
    type: 2, workNum: workNum
  }" :action="uploadURL" :file-list="codeFileList" :class="{ testupload: hideCode }" :multiple="true" :limit="10"
                      list-type="picture-card" name="img" :on-preview="codePreview" :on-remove="codeRemove"
                      :on-change="codeChange" :on-exceed="handleExceed" :before-upload="beforeAvatarUpload">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <div style="width: 100%;margin-left: 12px; text-align: left;color: #999;font-size: 12px;">
                      {{ $t('upload.h2') }}
                    </div>
                    <el-dialog :visible.sync="codeVisible">
                      <img ondragstart="return false" width="100%" :src="codePath" alt="">
                    </el-dialog>
                  </el-form-item>
                  <el-form-item :label="$t('upload.spjs') + '：'">
                    <el-upload :action="uploadURL" :class="{ testupload: hideVideo }" :http-request="handleUpload" :headers="{
                      'jwtToken': userData ? userData.jwtToken : ''
                    }" :data="{
    type: 3, workNum: workNum
  }" :limit="1" list-type="text" name="img" :file-list="videoFileList" :on-remove="videoRemove" :on-change="videoChange"
                      :before-upload="beforevideoUpload" :on-preview="handlePictureCardPreview" :on-success="handleSuccess">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" append-to-body>
                      <img ondragstart="return false" width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                    <div style="width: 100%;margin-left: 12px; text-align: left;color: #999;font-size: 12px;">
                      {{ $t('upload.h3') }}</div>
                  </el-form-item>
                </el-form>
              </div>
  
            </div>
  
            <div v-show="active == 3" class="upplate">
              <div>
                <el-form label-width="100px" :label-position="'left'" :model="formdetailModel" ref="formModelRef"
                  :rules="formdetailModelRules">
                  <el-form-item label-width="100px" :label="$t('upload.zpjs') + '：'" prop="detailMessage">
                    <el-input @input="inputChange2" class="customv" style=" background: #F2F2F2;;width: 1000px;"
                      :maxlength="800" :rows="15" type="textarea" :placeholder="$t('upload.h8')" v-model="formdetailModel.detailMessage">
                    </el-input>
                    <div style="width: 100%;text-align: left;color: #999;font-size: 12px;">{{ $t('upload.h4') }}</div>
                  </el-form-item>
                  <div style="display: flex;align-items: center;">
                    <el-form-item label-width="100px" :label="$t('upload.xm') + '：'" prop="name">
                      <el-input @input="inputChange3" class="customv2" style="width: 320px; " :placeholder="$t('upload.h5')"
                        v-model="formdetailModel.name">
                      </el-input>
                    </el-form-item>
                    <el-form-item style="margin-left: 20px;" label-width="80px" :label="$t('upload.sjh') + '：'" prop="phone">
                      <div style="display: flex;">
                        <div style="color: #999;">+</div>
                        <el-select style="margin-left: 12px;width: 150px;" v-model="subzonevalue" @change="inputChange6">
                          <el-option v-for="(item, index) in options" :key="index" :label="item.phone_code"
                            :value="item.phone_code">
                            <span style="float: left">{{item.phone_code  }}</span>
                             <span style="float: right; color: #8492a6; font-size: 13px">{{ lang?item.english_name:item.chinese_name }}</span>
                          </el-option>
                        </el-select>
                        <el-input @input="inputChange4" class="customv2" style="margin-left: 20px;" :maxlength="11"
                          :placeholder="$t('upload.h6')" v-model="formdetailModel.phone">
                        </el-input>
                      </div>
                    </el-form-item>
                  </div>
                </el-form>
              </div>
            </div>
  
            <!-- 参赛成功 -->
            <div v-show="active == 4" class="upplate succesview" style="">
              <img ondragstart="return false" class="" style="width: 200px;height: 187px;margin-top: 97px;" src="../assets/join/完成-插画.png" alt="">
              <div style="margin-top: 42px;font-size: 24px;font-weight: 500;line-height: 36px;color: #E45700;">
                {{ $t('upload.gx') }}
              </div>
            </div>
  
            <div v-if="active < 4" style="display: flex;justify-content: center;">
              <button v-if="active > 1" class="content-btn" @click="active-- <= 1 ? active = 1 : ''">
                {{ $t('upload.syb') }}
              </button>
              <button v-if="active != 3" class="content-btn" :class="{ zh: nextStatus }"
                :style="{ marginLeft: active > 1 ? '40px' : '' }" @click="nextClcik()">
                {{ $t('upload.xyb') }}
              </button>
              <button v-if="active == 3" class="content-btn" :class="{ zh: nextCommitStatus }"
                :style="{ marginLeft: active > 1 ? '40px' : '' }" @click="nextClcik()">
                {{ $t('upload.qrtj') }}
              </button>
            </div>
  
            <div v-else>
              <button class="content-btn" @click="otherObject()">
                {{ $t('upload.trzp') }}
              </button>
              <button class="content-btn" :style="{ marginLeft: active > 1 ? '40px' : '' }" @click="resumeObject()">
                {{ $t('upload.jxsc') }}
              </button>
            </div>
  
  
          </div>
        </div>
      </div>
  
    </div>
  </template>
    
  <script>
  // @ is an alias to /src
  
  // import * as API_URL from '../api/url'
  import topView from './components/homeTop.vue'
  import debounce from '@/libs/debounce'
  import smallTopview from './components/smallScreenTopview.vue'
  import subzone from './js/subzone'
  import * as API_URL from '../api/url'
  import upload from './js/fileUpload.js'
  import {managerUrl} from '@/libs/axios.js'
  
  // import { upload, uploadByPieces } from './js/upload.js'
  // import { Loading } from "element-ui";
  
  export default {
    name: 'art',
    components: {
      topView,
      smallTopview
    },
    data() {
      return {
        showSmall: false,
        screenWidth: 0,
        lang: 'zh',
        topHidden: false,
        selectType: 0, //第一步选择选项
        firstResult: null,
        active: 1,//进行到哪一步
        options: [],
        subzonevalue: '86',
        typeArr: [
          { title: this.$i18n.t('categraylm.jz'), message: this.$i18n.t('categraylm.jzd'), select: false, imgurl: '建筑-默认.png', imgurlSelect: '建筑-选中.png' },
          { title: this.$i18n.t('categraylm.pm'), message: this.$i18n.t('categraylm.pmd'), select: false, imgurl: '平面-默认.png', imgurlSelect: '平面-选中.png' },
          { title: this.$i18n.t('categraylm.gy'), message: this.$i18n.t('categraylm.gyd'), select: false, imgurl: '工业-默认.png', imgurlSelect: '工业-选中.png' },
          { title: this.$i18n.t('categraylm.sz'), message: this.$i18n.t('categraylm.szd'), select: false, imgurl: '数字-默认.png', imgurlSelect: '数字-选中.png' },
          { title: this.$i18n.t('categraylm.ty'), message: this.$i18n.t('categraylm.tyd'), select: false, imgurl: '交互-默认.png', imgurlSelect: '交互-选中.png' },
          { title: this.$i18n.t('categraylm.gg'), message: this.$i18n.t('categraylm.ggd'), select: false, imgurl: '公共-默认.png', imgurlSelect: '公共-选中.png' },
        ],
  
  
        formModel: {
          name: '',
        },
        formModelRules: {
          name: [
            { required: true, message: '请输入作品名称', trigger: 'blur' },
          ],
          headImg: [{ required: true, message: ' ', trigger: 'change' }],
          codeImg: [{ required: true, message: ' ', trigger: 'change' }],
        },
        dialogVisible: false,
        dialogImageUrl: '',
        formdetailModel: {
          detailMessage: '',
          name: '',
          phone: '',
          phoneCode: '+86',
        },
  
        formdetailModelRules: {
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' },],
          phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' },],
          detailMessage: [{ required: true, message: '请输入作品详细介绍', trigger: 'blur' },],
  
        },
  
        uploadURL: 'https://api.cspace.vip/shop/manager/goods/goods/img',
        previewPath: '',
        previewVisible: false,
        hideCode: false,
        hideUpload: false,
        hideVideo: false,
        codePath: '',
        codeVisible: false,
        codeFile: '',
        codeFileList: [],
        headFile: '',
        headFileList: [],//{url:'https://hszh-mars-public.oss-cn-beijing.aliyuncs.com/canvas.png'}
        videoFileList: [],
        videoFile: '',
        workNum: 0,
        userData: null,
        ossMessage: null
      }
    },
  
    computed: {
      nextStatus() {
        if (this.active == 1) {
          return false
        } else {
          let bl = true
          if (this.codeFileList[0] && this.headFileList[0] && this.formModel.name.length > 0) {
            bl = false
          }
          console.log('bl=', bl)
          return bl
        }
      },
      nextCommitStatus() {
        if (this.active == 1) {
          return false
        } else {
          let bl = true
          if (this.formdetailModel.detailMessage.length > 0 && this.formdetailModel.name.length > 0 && this.formdetailModel.phone.length > 0) {
            bl = false
          }
          return bl
        }
      }
    },
    methods: {
  
      // 处理图片预览效果
      handlePreview(file) {
        this.previewPath = file.url;
        this.previewVisible = true
      },
      // 处理图片预览效果
      codePreview(file) {
        this.codePath = file.url;
        this.codeVisible = true
      },
  
  
      remove(file, fileList) {
        if (fileList.length == 0) {
          this.formModelRules = {
            name: [
              { required: true, message: '请输入作品名称', trigger: 'blur' },
            ],
            headImg: [{ required: true, message: ' ', trigger: 'change' }],
            codeImg: this.codeFileList[0] ? '' : [{ required: true, message: ' ', trigger: 'change' }]
          }
          this.headFileList = []
        }
        console.log('remove file', file)
        this.removeOther(1, file.url)
        this.hideUpload = fileList.length >= 1;
      },
      codeRemove(file, fileList) {
        if (fileList.length == 0) {
          this.formModelRules = {
            name: [
              { required: true, message: '请输入作品名称', trigger: 'blur' },
            ],
            headImg: this.headFileList[0] ? '' : [{ required: true, message: ' ', trigger: 'change' }],
            codeImg: [{ required: true, message: ' ', trigger: 'change' }]
          }
  
          this.codeFileList = []
        }
        console.log('remove file', file)
        this.removeOther(2, file.url)
        this.hideCode = fileList.length >= 10;
      },
      videoRemove(file, fileList) {
        console.log('remove file', file)
        if (fileList.length == 0) {
          this.videoFileList = []
        }
        this.removeOther(3, file.videos)
        this.hideVideo = fileList.length >= 1;
      },
      videoChange(file, fileList) {
        this.videoFileList = fileList
        console.log(fileList)
        this.hideVideo = fileList.length >= 1;
      },
      codeChange(file, fileList) {
        this.codeFile = file;
        this.codeFileList = fileList;
        console.log(fileList)
        if (fileList.length == 1) {
          let { codeImg, ...data } = this.formModelRules;
          this.formModelRules = data;
          console.log('codeImg', codeImg)
        }
        this.$refs.formModelRef.clearValidate('codeImg');
        this.hideCode = fileList.length >= 10;
      },
      uploadChange(file, fileList) {
        this.headFile = file;
        this.headFileList = fileList;
        console.log(this.headFileList)
        if (fileList.length >= 1) {
          let { headImg, ...data } = this.formModelRules;
          this.formModelRules = data;
          console.log('headImg', headImg)
        }
        this.$refs.formModelRef.clearValidate('headImg');
        this.hideUpload = fileList.length >= 1;
      },
  
      handleExceed(files, fileList) {
        console.log(fileList,files)
        this.$message.warning(this.$i18n.t('hint.cc'));
      },
  
      messageClick() {
        this.$message.error('点击');
      },
  
      selectTable(e) {
        console.log(e)
        this.$router.push({
          name: 'Home',
          params: {
            index: e
          }
        })
      },
  
      changeLang() {
        let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
        if (isZh === 'zh') {
          localStorage.setItem('lang', 'en'); // en表示英文，zh表示中文，可根据自己喜好设定，尽量通俗易懂
          this.$i18n.locale = 'en'
          this.lang = 'en'
        } else {
          localStorage.setItem('lang', 'zh');
          this.$i18n.locale = 'zh'
          this.lang = 'zh'
        }
        window.location.reload();
      },
  
      // 下一步
      nextClcik() {
  
        console.log(this.active)
        if (this.active == 1) { //上传类目
          this.uploadType()
         
        } else {
          if (!this.nextStatus) {
            if (this.active == 3 && !this.nextCommitStatus) {
              this.commitWork()
            }
            this.active = 3
            localStorage.setItem('editActive',this.active)
          } else {
            this.$message.warning(this.$i18n.t('hint.xx'))
          }
        }
  
      },
  
      // 选着类目上传
      uploadType() {
        const val = this.selectType + 1
        API_URL.chooseCategory({ categoryId: val, workNum: this.workNum ? this.workNum : '' }).then((res) => {
          if (res.code == 0) {
            this.workNum = res.data
            this.active = 2
            localStorage.setItem('editActive',this.active)
          }
        });
      },
  
  
      inputChange(e) {
        console.log(e)
        this.uploadOther(1, e)
      },
      inputChange2(e) {
        console.log(e)
        this.uploadOther(2, e)
      },
      inputChange3(e) {
        console.log(e)
        this.uploadOther(3, e)
      },
      inputChange4(e) {
        console.log(e)
        this.uploadOther(4, e)
      },
      inputChange6(e) {
        console.log(e)
        this.uploadOther(6, e)
      },
  
      resetScrennwidth: debounce(function () {
        this.screenWidth = document.body.clientWidth
      }, 300),
  
  
      // 实时保存文字 / 视频url
      uploadOther: debounce(function (type, content) {
        if (type == 5) {
          API_URL.saveOther({ type: type, workNum: this.workNum, content: content.url, name: content.name }).then((res) => {
            if (res.code == 0) {
              console.log(res.data)
            }
          });
        } else {
          API_URL.saveOther({ type: type, workNum: this.workNum, content: content }).then((res) => {
            if (res.code == 0) {
              console.log(res.data)
            }
          });
        }
  
      }, 1000),
  
      // 自定义上传
      // 自定义上传操作
      handleUpload(op) {
        let dir = this.ossMessage.dir
        upload(
          op.file,
          res => {
            let temp = {
              name: res.attachment,
              url: res.host + '/' + res.aliyunAddress
            }
            this.host = res.host
            console.log('temp', temp, res)
            op.onSuccess(temp)
          },
          err => {
            console.log(err)
          },
          res => {
            console.log('progress', res)
            op.onProgress(res)
          },
          dir
        )
      },
  
      // 视频上传显示进度
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
  
      // 视频上传成功后触发
      handleSuccess(response) {
        this.uploadOther('5', response)
      },
  
      // 现在图片格式
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          this.$message.error(this.$i18n.t('hint.sctx'));
        }
        return isLt2M;
      },
  
      beforevideoUpload(file) {
        const isJPG = file.type === 'video/mp4';
        const isLt2M = file.size / 1024 / 1024 < 1024;
        if (!isJPG) {
          this.$message.error(this.$i18n.t('hint.scgs'));
        }
        if (!isLt2M) {
          this.$message.error(this.$i18n.t('hint.scdx'));
        }
        return isLt2M && isJPG;
      },
  
      userMenuHandler(e) {
        console.log(e)
        if (e < 6) {
          this.$router.push(
            {
              name: 'user',
              params: {
                index: e
              }
            }
          )
        } else {
          localStorage.setItem('userData', '')
          localStorage.setItem('accessToken', '')
          this.$router.push({
            name: 'Home',
          })
        }
  
      },
      // 确认提交
      commitWork() {
        API_URL.commitWork({ workNum: this.workNum }).then((res) => {
          if (res.code == 0) {
            this.active = 4
          }
        });
      },
      otherObject() {
        this.$router.push('/art')
      },
  
      // 继续上传
      resumeObject() {
        window.location.reload()
      },
  
      getOssMessage() {
        API_URL.ossMessage().then((res) => {
          console.log('res.data=', res.data)
          this.ossMessage = res.data
        });
      },
      // 删除图片
      removeOther(type, url) {
        console.log(type, url)
        API_URL.removeOther({ type: type, content: url, workNum: this.workNum }).then((res) => {
          if (res.code == 0) {
            console.log('删除成功 res.data=', res.data)
            this.$message.success(this.$i18n.t('hint.sccg'))
          }
  
        });
      },
      // 获取草稿箱内容
      getMyDraft() {
        API_URL.hasDraft().then((res) => {
          // console.log('res.data=', res.data)
          if (res.code == 0) {
            if (res.data.drft) {
              const drft = res.data.competitionWorkDrftVo
              this.setNormalValue(drft)
            }
          }
        });
      },
  
      // 设置默认信息
      setNormalValue(drft) {
        // this.active = drft.step > 0 ? drft.step : 1 //
        this.formModel.name = drft.worksName
        this.workNum = drft.workNum  //
        this.selectType = drft.categoryId - 1 //
        let codearr = []
        if (drft.images) {
          for (let index = 0; index < drft.images.length; index++) {
            const element = drft.images[index];
            codearr.push({ url: element })
          }
        }
        if (drft.imgs) {
          for (let index = 0; index < drft.imgs.length; index++) {
            const element = drft.imgs[index];
            codearr.push({ url: element })
          }
        }
  
        this.codeFileList = codearr
        this.headFileList = drft.coverImg ? [{ url: drft.coverImg }] : []
        this.videoFileList = drft.videoName ? [{ name: drft.videoName, videos: drft.videos }] : []
  
        if (this.headFileList.length > 0) {
          this.hideUpload = true
        }
        if (this.codeFileList.length >= 10) {
          this.hideCode = true
        }
        if (this.videoFileList.length > 0) {
          this.hideVideo = true
        }
  
        this.formdetailModel.detailMessage = drft.worksIntroduces ? drft.worksIntroduces : ''
        this.formdetailModel.name = drft.authorName ? drft.authorName : ''
        this.subzonevalue = drft.areaCode ? drft.areaCode : '86' //
        this.formdetailModel.phone = drft.phone ? drft.phone : '' //
      },
  
  
      // 编辑我的作品 获取信息
      getDetailMessage(code) {
        this.workNum = code
        API_URL.objectDetail({ worksId: code }).then((res) => {
          if (res.code == 0) {
            this.setNormalValue(res.data)
          }
        });
      },
  
      // 刷新判断是编辑 还是草稿箱
      reloadPageStatus(){
        if (this.$route.params.worksId) {
            localStorage.setItem('worksId',this.$route.params.worksId)
             this.getDetailMessage(this.$route.params.worksId)
             this.active = 1
        }else{
            this.active =  localStorage.getItem('editActive')
            const worksidd = localStorage.getItem('worksId')
            this.getDetailMessage(worksidd)
        }
      }
    },
  
    mounted() {
      //获取屏幕尺寸
      // window.addEventListener('scroll', this.viewScroll, true)
      this.options = subzone //国际地区分号
      console.log(this.options)
      
      this.uploadURL = managerUrl+'/competition/work/upload'
      this.userData = JSON.parse(localStorage.getItem('userData') ? localStorage.getItem('userData') : '')
      this.reloadPageStatus()
      this.getOssMessage()
  
    },
  
    watch: {
  
    }
  
  }
  
  </script>
    
  <style lang="scss" scoped>
  @import url('./css/join.css');
  
  .join {
    box-sizing: border-box;
    overflow-x: hidden;
    height: fit-content;
    background-color: black;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  .topv {
    // position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    box-sizing: border-box;
  }
  
  
  .testupload /deep/ .el-upload {
    display: none
  }
  
  .justcontent-left /deep/ .el-upload {
    display: flex;
    justify-content: left;
  }
  
  
  ::v-deep .customv .el-textarea__inner {
    background-color: #F2F2F2 !important;
    color: #999999 !important;
  }
  
  ::v-deep .customv2 .el-input__inner {
    background-color: #F2F2F2 !important;
    color: #999999 !important;
  }
  
  
  .zh {
    margin-top: 40px;
    width: 200px;
    height: 54px;
    text-align: center;
    line-height: 54px;
    color: #fff;
    font-size: 20px;
    margin-bottom: 40px;
    background-image: url('../assets/join/置灰按钮背景.png');
    background-size: cover;
    border: none;
    background-color: #d8d8d880;
  }</style>
    