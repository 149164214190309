<template>
    <div class="listview1">
        <div class="listview-topview1">
            <div class="listview-topview-btn1 " :class="{ tselect1: tag == 1 }" @click="tagSelect(1)">{{$t('tableHeader.zxzp')}}</div>
            <div style="width: 1px;height: 14px;background-color: #999999;margin-right: 10px;"></div>
            <div class="listview-topview-btn1" :class="{ tselect1: tag == 2 }" @click="tagSelect(2)">{{$t('tableHeader.rmzp')}}</div>
            <div style="flex: 1;"></div>
            <div class="menu_seacrh1">
                <el-input class="customv2" @change="searchHandler" clearable v-model="searchName"
                    :placeholder="searchP"></el-input>
                <img ondragstart="return false" class="menu_seacrh_btn1" @click.stop="toSearch()" src="../../assets/art/搜索.png" alt="">
            </div>
        </div>
        <div class="listview-list1" style="min-height: 600px;">
            <div class="listview-list-item1" v-for="(item, index) in list" :key="index" @click="itemClick(item)">
                <img ondragstart="return false" class="listview-list-item-img1" :src="item.coverImg" alt="">
                <div class="listview-list-item-title1">{{ item.worksName }}</div>
                <div class="listview-list-item-type1">{{ item.categoryName }}</div>
                <div class="listview-list-item-nameview1">
                    <img ondragstart="return false" class="listview-list-item-nameview-img1" :src="item.authorHeadImg" alt="">
                    <div class="listview-list-item-nameview-text1">{{ item.authorName }}</div>
                </div>
                <div v-if="active != 2 && active <500" class="listview-list-item-shadow1">
                    <img ondragstart="return false" class="listview-list-item-shadow-img1" src="../../assets/art/投票.png" alt="">
                    <div class="listview-list-item-shadow-text1">{{ item.ticketNum }}</div>
                    <div v-if="item.vote " class="listview-list-item-shadow-btn1" @click.stop="toupiao(item)">
                        {{$t('base.tp') }}
                    </div>
                    <div v-else class="listview-list-item-shadow-btn1" style="background-color: #999999;">{{$t('base.ytp') }}</div>
                </div>
                <div v-if="active >=500" class="listview-list-item-shadow1">
                    <img ondragstart="return false" class="listview-list-item-shadow-img1" src="../../assets/art/投票.png" alt="">
                    <div class="listview-list-item-shadow-text1">{{ item.ticketNum }}</div>
                    <div class="listview-list-item-shadow-btn2" @click.stop="jumpTodetail(item)">{{$t('base.bj') }}</div>
                </div>
                <div v-if="active == 2" class="listview-list-item-shadow1">
                    <div style="flex: 1;"></div>
                    <div class="listview-list-item-shadow-btn2" @click.stop="editItem(item)">{{$t('base.bj') }}</div>
                </div>
            </div>

            <div v-if="!list[0]" style="width: 100%;display: flex;margin-top: 100px; justify-content: center;">
                <el-empty :description="$t('base.zwsj')"></el-empty>
            </div>

        </div>
        <div v-if="list[0]" style="width: 100%;margin-top: 20px;">
            <el-pagination :background="true"  layout="prev, pager, next" :total="total" :hide-on-single-page="true" :page-size="6" @current-change="handleCurrentChange">
            </el-pagination>
        </div>



    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: '1',
            pageNum: 1,
            tag: 1,
            searchName: '',
            searchP:this.$i18n.t('tableHeader.search')

        }
    },
    props: {
        total: Number,
        totalPages: Number,
        list: Array,
        active:Number
    },
    methods: {
        tagSelect(e) {
            if (this.tag != e) {
                this.tag = e
                this.$emit('search', { type: 3, value: this.tag })
            }

        },
        handleSelect(key) {
            // this.$emit('menu', key)
            this.$emit('search', { type: 2, value: key })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.$emit('page', val)
        },
        itemClick(item) {
            this.$emit('itemClick', item)
        },
        toupiao(item) {
            this.$emit('toupiao', item)
        },

        searchHandler(e) {
            console.log('searchHandler', e)
            this.searchName = e
            this.$emit('search', { type: 1, value: e })
        },

        toSearch() {
            console.log('toSearch', this.searchName)
            this.$emit('search', { type: 1, value: this.searchName })
        },
        editItem(e) {
            console.log('editItem')
            this.$emit('editItem',e)
        },
        jumpTodetail(e) {
            console.log('jumpTodetail')
            this.$emit('myItem',e)
        },

        currentChangeHandler(e){
            this.$emit('pageChange',e)
            console.log('currentChangeHandler',e)
        }
    }
}
</script>

<style  scoped>
@import url('../css/useritemcontent.css');

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #E45700;
    color: #FFF;
}


::v-deep .cust .el-input__inner {
    flex: 1;
    font-size: 14px;
    color: #333;
    border: none;
    outline: medium;
}


::v-deep .customv2 .el-input__inner {
    background-color: #F2F2F2 !important;
    color: #999999 !important;
    border: none !important;
    /* height: 25px; */
    align-items: center;
}
</style>