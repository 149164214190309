<template>
  <div>
    <div class="user">
      <div style="position: relative; width:100%;">
        <div id="top" class="topv">
          <top-view ref="toptitle" @changeLang="changeLang" @selectTable="selectTable" @menu="userMenuHandler"></top-view>
        </div>
        <img ondragstart="return false" style="position: absolute;top: 82px;left: 0px;width: 100%;"
          src="../assets/art/背景_01.jpg" alt="">

        <div class="cardtab">
          <div class="cardtab-header">
            <img ondragstart="return false" style="width: 1280px;position: relative;" src="../assets/art/别表头.png" alt="">
            <div style="position: absolute; top: 15px;left: 39px;font-size: 24px;font-weight: bold;color: #E45700;">
              {{ $t('tableHeader.grzx') }}
            </div>
          </div>
          <div class="cardtab-content">
            <div style="display: flex;width: 100%;">
              <el-menu style="width:350px ;height: 280px; font-weight: bold;" background-color="#fff" text-color="#333333"
                active-text-color="#E45700" :unique-opened="true" :default-active="activeIndex + ''" class="el-menu-demo"
                @select="handleSelect">
                <el-menu-item style="text-align: left;" index="1">{{
                  menuList[0].value
                }}</el-menu-item>
                <el-menu-item style="text-align: left;" index="2">{{
                  menuList[1].value
                }}</el-menu-item>

                <el-submenu index="3" style="text-align: left;" @click.native="submenuHandler(3)">
                  <template slot="title">
                    <span>{{ menuList[2].value }}</span>
                  </template>
                  <el-menu-item-group style="text-align: left;margin-top: -15px; ">
                    <el-menu-item class="line3"
                      @click.stop.native="handleSelect((menuList[2].id * 10 + '' + index), [menuList[2].id, (menuList[2].id * 10 + '' + index)])"
                      v-for="(item, index) in menuChildren" :index="(menuList[2].id * 10 + '' + index)" :key="index">{{
                        (item.value + '(' + (menuListData[index] ? menuListData[index].cateGoryNum ?
                          menuListData[index].cateGoryNum : '0' : '0') + ')')
                      }}</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>

                <el-submenu index="4" style="text-align: left;" @click.native.stop="submenuHandler(4)">
                  <template slot="title">
                    <span>{{ menuList[3].value }}</span>
                  </template>
                  <el-menu-item-group style="text-align: left;margin-top: -15px;">
                    <el-menu-item class="line3"
                      @click.stop.native="handleSelect((menuList[3].id * 10 + item.id), [menuList[3].id, (menuList[3].id * 10 + item.id)])"
                      v-for="(item, index) in menuChildren" :index="(menuList[3].id * 10 + item.id)" :key="index">{{
                        (item.value + '(' + (menuListData[index] ? menuListData[index].cateGoryNum ?
                          menuListData[index].cateGoryNum : '0' : '0') + ')')
                      }}</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>

                <el-submenu index="5" style="text-align: left;" @click.native="submenuHandler(5)">
                  <template slot="title">
                    <span>{{ menuList[4].value }}</span>
                  </template>
                  <el-menu-item-group style="text-align: left;margin-top: -15px;">
                    <el-menu-item class="line3"
                      @click.stop.native="handleSelect((menuList[4].id * 10 + item.id), [menuList[4].id, (menuList[4].id * 10 + item.id)])"
                      v-for="(item, index) in menuChildren" :index="(menuList[4].id * 10 + item.id)" :key="index">{{
                        (item.value + '(' + (menuListData[index] ? menuListData[index].cateGoryNum ?
                          menuListData[index].cateGoryNum : '0' : '0') + ')')
                      }}</el-menu-item>
                  </el-menu-item-group>
                </el-submenu>

              </el-menu>
              <div v-show="activeIndex == 1" class="c-plate">

                <div class="flex-derection-column">
                  <div class="plate-flexview">
                    <img ondragstart="return false" class="uimg" :src="userData ? userData.headImg : ''" alt="">
                    <div class="u-nameview">
                      <div class="u-nameview-name">{{ userData ? userData.userName : '' }}</div>

                      <!-- <img ondragstart="return false" style="width: 129px;margin-top: 10px;" src="../assets/home/从微信获取更新.png" alt=""> -->
                    </div>
                  </div>
                  <div class="u-plateline">
                    <div class="u-plateline-title">{{ $t('user.xm') }}</div>
                    <el-input class="u-plateline-input customv2" style="width: 320px; font-size: 14px;"
                      v-model="formModel.realName" placeholder="请输入姓名"></el-input>
                  </div>
                  <div class="u-plateline">
                    <div class="u-plateline-title">{{ $t('user.dh') }}</div>

                    <div
                      style="border-radius: 1px; margin-left: 30px;background-color: #F2F2F2;height: 40px;line-height: 40px;width: 30px;text-align: center;color: #999;">
                      +</div>
                    <el-select class="customv2" style="width: 67px;background:#F2F2F2 ;margin-left: -10px;"
                      v-model="formModel.areaCode">
                      <el-option v-for="(item, index) in options" :key="index" :label="item.phone_code"
                        :value="item.phone_code">
                        <span style="float: left">{{ item.phone_code }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{
                          lang ? item.english_name : item.chinese_name }}</span>
                      </el-option>
                    </el-select>
                    <el-input class="u-plateline-input customv2" style="width: 223px;margin-left: 10px;font-size: 14px;"
                      v-model="formModel.phone" maxlength="11" placeholder="请输入电话"></el-input>
                  </div>
                  <div class="u-plateline">
                    <div class="u-plateline-title">{{ $t('user.dz') }}</div>
                    <el-cascader @change="cascaderHandler" :placeholder="$t('user.sw')" class="customv2"
                      style="margin-left: 30px;width: 320px;" v-model="checkCity" :options="cityList" />
                  </div>
                  <div class="u-plateline">
                    <div class="u-plateline-title" style="width: 70px;margin-left: -5px;">{{ $t('user.xxdz') }}</div>
                    <el-input class="u-plateline-input customv2" style="width: 320px;margin-left: 10px; font-size: 14px;"
                      v-model="formModel.addressDetail" :placeholder="$t('user.xxdz')"></el-input>
                  </div>
                  <div class="u-bottom-view">
                    <div class="u-bottom-view-item-g" @click="cancelChange()">{{ $t('user.qx') }}</div>
                    <div class="u-bottom-view-item-z" @click="saveUserMessage()">{{ $t('user.bc') }}</div>
                  </div>
                </div>
              </div>

              <div v-show="activeIndex != 1" style="width: 1008px;">
                <usercontent :active="Number(activeIndex)" @search="searchHandler" @page="pageChange"
                  @itemClick="itemClick" :list="list" :total="total" @myItem="myItemHandler" @toupiao="voteHandler"
                  :totalPages="totalPages" @editItem="editItem()">
                </usercontent>
              </div>
            </div>


          </div>

        </div>
      </div>

      <div class="toupiao" @click="jumptoart()">
        <div class="toupiaotext">{{ $t('homePage.yxcy') }}</div>
        <div class="toupiaobtn">{{ $t('homePage.tp') }}</div>
      </div>
      <div class="canshai" @click="jumptojoin()">
        <div class="toupiaotext">{{ $t('homePage.sczp') }}</div>
        <div class="toupiaobtn">{{ $t('homePage.sc') }}</div>
      </div>

    </div>

    <el-backtop :right="100" :bottom="100" style="background-color: rgba(0, 0, 0, 0.01);">
      <img ondragstart="return false" style="width: 50px;" src="../assets/home/top.png" alt="">
    </el-backtop>
  </div>
</template>
  
<script>
import topView from './components/homeTop.vue'
import subzone from './js/subzone'
import city from './js/city'
import usercontent from './components/userItemContent.vue'
// import debounce from '@/libs/debounce'
import * as API_URL from '../api/url'
import { managerUrl } from '@/libs/axios.js'

export default {
  name: 'art',
  components: {
    topView,
    usercontent
  },
  data() {
    return {
      lang: 'zh',
      options: [],
      activeIndex: '1',
      userMessage: null,
      searchForm: {
        pageNum: 1,
        pageSize: 6,
        orderBy: 1, //1时间 2热度
        categoryId: '',
        name: '',
      },
      formModel: {
        realName: '',
        areaCode: '86',
        phone: '',
        address: '',
        addressDetail: '',
        addressArr: ''
      },
      formModelRules: {
        name: [
          { required: true, message: '请输入作品名称', trigger: 'blur' },
        ],
        headImg: [{ required: true, message: ' ', trigger: 'change' }],
        codeImg: [{ required: true, message: ' ', trigger: 'change' }],
      },

      menuChildren: [{ value: this.$i18n.t('categraylm.qb'), id: '0' },
      { value: this.$i18n.t('categraylm.jz'), id: '1' },
      { value: this.$i18n.t('categraylm.pm'), id: '2' },
      { value: this.$i18n.t('categraylm.gy'), id: '3' },
      { value: this.$i18n.t('categraylm.sz'), id: '4' },
      { value: this.$i18n.t('categraylm.ty'), id: '5' },
      { value: this.$i18n.t('categraylm.gg'), id: '6' },
      ],

      menuList: [{ value: this.$i18n.t('user.gr'), id: '1' },
      { value: this.$i18n.t('user.cg'), id: '2' },
      { value: this.$i18n.t('user.sc'), id: '3', },
      { value: this.$i18n.t('user.tp'), id: '4' },
      { value: this.$i18n.t('user.zp'), id: '5' },
      ],
      uploadURL: 'https://api.cspace.vip/shop/manager/goods/goods/img',
      previewPath: '',
      previewVisible: false,
      userData: null,
      subzonevalue: 86,
      cityList: [],
      checkCity: [],
      list: [],
      total: 0,
      totalPages: 0,

      menuListData: [],
      getDataKey: '',
      getDataKeyPath: []

    }
  },

  computed: {
    // subzonev(newValue,oldValue){
    //   return '+'+this.subzonevalue
    // }
  },
  methods: {
    selectTable(e) {
      this.$router.push({
        name: 'Home',
        params: {
          index: e
        }
      })
    },

    changeLang() {
      let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
      if (isZh === 'zh') {
        localStorage.setItem('lang', 'en'); // en表示英文，zh表示中文，可根据自己喜好设定，尽量通俗易懂
        this.$i18n.locale = 'en'
        this.lang = 'en'
      } else {
        localStorage.setItem('lang', 'zh');
        this.$i18n.locale = 'zh'
        this.lang = 'zh'
      }
      window.location.reload();
    },

    searchHandler(e) {
      if (e.type == 1) {
        this.searchForm.name = e.value
      } else if (e.type == 2) {
        this.searchForm.categoryId = e.value
      } else if (e.type == 3) {
        this.searchForm.orderBy = e.value
      }

      this.getData(this.getDataKey, this.getDataKeyPath)
      this.getCategoryList(this.getDataKey.substring(0, 1))
    },

    // 切换页面
    pageChange(page) {
      this.searchForm.pageNum = page
      // 
      this.getData(this.getDataKey, this.getDataKeyPath)
    },

    // getList() {
    //   console.log('getList')
    //   API_URL.list(this.searchForm).then((res) => {
    //     if (res.code == 0) {
    //       this.list = res.data.records
    //       this.total = res.data.total 
    //       console.log('total',res.data)
    //       this.totalPages = res.data.pages

    //     }
    //   });
    // },

    // 同步微信昵称
    reloadWxUser() {
      window.open('https://open.weixin.qq.com/connect/qrconnect?appid=wxee731c0f9dc6ad53&redirect_uri=https://design2023.marsbase1.com&response_type=code&scope=snsapi_login')
    },

    jumptoart() {
      this.$router.push('/art')
      // 
    },

    jumptojoin() {
      this.$router.replace('/join')
    },

    // 点击作品
    itemClick(item) {
      if (this.activeIndex != '2' && this.activeIndex < '500') {
        window.open('/#/artDetail?id=' + item.worksId + '&&status=0')
      } else if (this.activeIndex >= '500') {
        window.open('/#/artDetail?id=' + item.worksId + '&&status=1')
      } else {
        window.open('/#/join')
      }

      // this.$router.push('./artDetail')
    },

    // 投票
    voteHandler(e) {
      API_URL.vote({ worksId: e.worksId }).then((res) => {
        if (res.code == 0) {
          this.$message.success('投票成功')
          this.getData(this.getDataKey, this.getDataKeyPath)
        } else {
          this.$message.info(res.message)
        }
      });
    },

    // 刷新投票数据
    // reloadVoteData(e) {
    //   API_URL.vote({ worksId: e.worksId }).then((res) => {
    //     if (res.code == 0) {
    //       this.$message.success('投票成功')
    //       this.getData(this.getDataKey, this.getDataKeyPath)
    //     } else {
    //       this.$message.info(res.message)
    //     }
    //   });
    // },

    // 获取用户初始信息
    getUserMessage() {
      API_URL.selectUserInfo().then((res) => {
        if (res.code == 0) {
          this.formModel = res.data
          if (!this.formModel.areaCode) {
            this.formModel.areaCode = '86'
          }
          this.checkCity = JSON.parse(res.data.addressArr)
        } else {
          this.$message.info(res.message)
        }
      });
    },

    // 保存用户地址信息
    saveUserMessage() {
      this.formModel.addressArr = JSON.stringify(this.checkCity)
      API_URL.updateUserInfo(this.formModel).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.message)
          this.getUserMessage()
        } else {
          this.$message.info(res.message)
        }
      });
    },

    // 点击一级菜单
    submenuHandler(e) {
      this.getCategoryList(e)
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isLt2M;
    },

    userMenuHandler(e) {
      console.log(e)

      if (Number(e) < 6) {
        this.activeIndex = e > 2 ? e + '00' : e
        this.getData(e, [e, e > 2 ? e + '00' : ''])
        if (this.activeIndex > 2) {
          this.getCategoryList(e)
        }
        localStorage.setItem('activeKeyPath', JSON.stringify([e, this.activeIndex]))
      }else{
        console.log('退出登录')
        localStorage.setItem('userData','')
        localStorage.setItem('accessToken','')
        this.$router.push('/')
      }

    },

    // 地区选择
    cascaderHandler(e) {
      this.formModel.address = ''
      for (let index = 0; index < e.length; index++) {
        const element = e[index];
        this.formModel.address += element
      }
    },

    // 我的作品点击
    myItemHandler(item) {
      window.open('/#/artDetail?id=' + item.worksId + '&&status=1')
    },
    // 选择menu
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
      console.log('keypath=', keyPath)
      this.activeIndex = key
      this.getData(key, keyPath)
      localStorage.setItem('activeKeyPath', JSON.stringify(keyPath))

    },

    editItem() {
      this.jumptojoin()
    },

    // get customize city
    getCity() {
      const allCityInformation = city;
      this.cityList = this.traverse(86, allCityInformation);
    },

    // handle city
    traverse(key, obj) {
      let objes = obj[key], arr = [];
      for (let i in objes) {
        arr.push({
          label: objes[i],
          value: objes[i],
          children: obj[i] && this.traverse(i, obj)
        });
      }
      return arr;
    },


    cancelChange() {
      this.getUserMessage()
      this.checkCity = ''
    },

    // 获取草稿箱
    getDrafts() {
      API_URL.drafts().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.list = [res.data]
          } else {
            this.list = []
          }

        }
      });
    },

    //我的收藏列表
    getCollectList() {
      API_URL.collectList(this.searchForm).then((res) => {
        if (res.code == 0) {
          this.list = res.data.records
          this.total = res.data.total
          this.totalPages = res.data.pages
        }
      });
    },


    // 获取muenlist数据
    getCategoryList(e) {
      if (e == 3) {
        API_URL.collectListCategory({ name: this.searchForm.name }).then((res) => {
          if (res.code == 0) {
            this.menuListData = res.data
          }
        });
      } else if (e == 4) {
        API_URL.voteListCategory({ name: this.searchForm.name }).then((res) => {
          if (res.code == 0) {
            this.menuListData = res.data
          }
        });
      } else if (e == 5) {
        API_URL.workCollectListCategory({ name: this.searchForm.name }).then((res) => {
          if (res.code == 0) {
            this.menuListData = res.data
          }
        });
      }

    },

    // 投票记录
    getrecord() {
      API_URL.record(this.searchForm).then((res) => {
        if (res.code == 0) {
          this.list = res.data.records
          this.total = res.data.total
          this.totalPages = res.data.pages
        }
      });


    },


    // 我的作品
    getMyRecord() {
      API_URL.myrecord(this.searchForm).then((res) => {
        if (res.code == 0) {
          this.list = res.data.records
          this.total = res.data.total
          this.totalPages = res.data.pages
        }
      });
    },


    getData(key, keyPath) {

      this.getDataKey = key
      this.getDataKeyPath = keyPath
      const val = Number(keyPath[0])
      console.log('getData.val=', val)
      if (val == 1) {
        return
      }

      var id = 0
      if (keyPath[1]) {
        id = Number(keyPath[1]) - Number(keyPath[0]) * 100
        id = id > 0 ? id : ''
      } else {
        id = ''
      }

      this.searchForm.categoryId = id

      if (val == 2) {
        this.getDrafts()
      } else if (val == 3) {
        this.getCollectList()
      } else if (val == 4) {
        this.getrecord()
      } else if (val == 5) {
        this.getMyRecord()
      }
    },

    // 监听微信扫码登录 回传code
    ntfScan() {

      var url = window.location.href;             //获取当前url
      // var dz_url = url.split('#')[0];                //获取#/之前的字符串
      var cs = url.split('?')[1];                //获取?之后的参数字符串
      var cs_arr = cs ? cs.split('&') : [];                    //参数字符串分割为数组
      var cs2 = {};
      for (var i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
        cs2[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      const code = cs2.code;
      if (code) {
        this.testCode(code)
      }

    },

    testCode(val) {

      API_URL.login({ code: val }).then((res) => {
        if (res.code == 0) {
          localStorage.setItem('userData', JSON.stringify(res.data))
          localStorage.setItem('accessToken', JSON.stringify(res.data.jwtToken))
          setTimeout(() => {
            var url = window.location.href;             //获取当前url
            var dz_url = url.split('#')[0];                //获取#/之前的字符串
            var cs = dz_url.split('?')[0];
            window.location.href = cs
          }, 100);

          this.userData = JSON.parse(localStorage.getItem('userData') ? localStorage.getItem('userData') : '')
        }
      });
    },



  },

  mounted() {
    //获取屏幕尺寸
    this.options = subzone //国际地区分号

    this.ntfScan()  //微信扫码同步信息

    // this.uploadURL = (process.env.NODE_ENV === 'development' ? 'https://api.haoqi.fun/java-home/competition/work/upload' : 'https://api.haoqi.fun/java-home/competition/work/upload')
    this.uploadURL = managerUrl + '/competition/work/upload'
    this.userData = JSON.parse(localStorage.getItem('userData') ? localStorage.getItem('userData') : '')
    this.getUserMessage() //获取用户地址信息


    // 保存默认选择菜单
    // console.log('active =', localStorage.getItem('activeKeyPath'))
    // 初次跳转 获取数据
    if (this.$route.params.index) {
      this.activeIndex = this.$route.params.index > 2 ? this.$route.params.index + '00' : this.$route.params.index
      this.getData(this.$route.params.index, [this.$route.params.index, this.$route.params.index > 2 ? this.$route.params.index + '00' : ''])
      if (this.activeIndex > 2) {
        this.getCategoryList(this.$route.params.index)
      }
      localStorage.setItem('activeKeyPath', JSON.stringify([this.$route.params.index, this.activeIndex]))
    } else {

      // 刷新页面 ，获取缓存数据
      const keyp = JSON.parse(localStorage.getItem('activeKeyPath'))
      this.activeIndex = keyp[0] > 2 ? keyp[0] + '00' : keyp[0]
      if (keyp) {
        if (Number(keyp[0]) > 2) {
          this.getData(keyp[0], [keyp[0], keyp[0] > 2 ? keyp[0] + '00' : ''])
          this.getCategoryList(keyp[0])
        } else {
          this.getData(keyp[0], [keyp[0], keyp[0] > 2 ? keyp[0] + '00' : ''])
        }
      }

    }

    this.getCity(); //获取3级地区数据
    // this.getList()
  },

  watch: {
  }

}

</script>
  
<style lang="scss" scoped>
@import url('./css/user.css');

.user {
  box-sizing: border-box;
  overflow-x: hidden;
  height: fit-content;
  background-color: black;
  position: relative;
  width: 100%;
  height: 100vh;
}

.topv {
  // position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  box-sizing: border-box;
}


.testupload /deep/ .el-upload {
  display: none
}


::v-deep .customv .el-textarea__inner {
  background-color: #F2F2F2 !important;
  color: #999999 !important;
}

::v-deep .customv2 .el-input__inner {
  background-color: #F2F2F2 !important;
  color: #999999 !important;
  border: none;
}


.zh {
  margin-top: 40px;
  width: 200px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  color: #fff;
  font-size: 20px;
  margin-bottom: 40px;
  background-image: url('../assets/join/置灰按钮背景.png');
  background-size: cover;
  border: none;
  background-color: #d8d8d880;
}


.toupiao {
  position: fixed;
  right: calc(100vw * (40 / 1920));
  top: calc(100vw * (200 / 1920));
  width: calc(100vw * (212 / 1920));
  height: calc(100vw * (252 / 1920));
  font-size: 20px;
  border-radius: 12px;
  background-image: url('../assets/home/侧边-投票.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}

.toupiaotext {
  margin-top: calc(100vw * (100 / 1920));
  font-weight: bold;
  font-size: calc(100vw * (20 / 1920));
  color: #333;
  margin-right: calc(100vw * (12 / 1920));
}

.toupiaobtn {
  margin-top: calc(100vw * (17 / 1920));
  margin-right: calc(100vw * (12 / 1920));
  font-weight: bold;
  font-size: calc(100vw * (32 / 1920));
  width: calc(100vw * (170 / 1920));
  ;
  height: calc(100vw * (54 / 1920));
  ;
  color: #fff;
  background-image: url('../assets/home/按钮.png');
  background-size: cover;
  line-height: calc(100vw * (54 / 1920));
  ;
}

.canshai {
  position: fixed;
  right: calc(100vw * (40 / 1920));
  top: calc(100vw * (500 / 1920));
  width: calc(100vw * (212 / 1920));
  height: calc(100vw * (252 / 1920));
  background-image: url('../assets/home/侧边-参赛.png');
  background-size: cover;
  font-size: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  white-space: pre-wrap;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 95%;
  left: 2.5%;
}
</style>
  