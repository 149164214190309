<template>

    <div >
      <div class="home" style="display: flex;flex-direction: column;align-items: center;min-width: 1200px; ">
      <div id="top" class="topv">
        <div style="width: 100%;height: 100%;display: flex;padding: 20px;box-sizing: border-box; justify-content: center;align-items: center;background-color: rgba(0, 0, 0, 0.5);">
            <div @click="jumptoMars()" style="position: absolute;left: 40px;display: flex;align-items: center;padding: 20px;">
                <img ondragstart="return false" class="topview-logo1" src="../assets/mars/logo-pic@3x.png" alt="">
                <img ondragstart="return false" class="topview-logoText1" style="width: 120px;" src="../assets/mars/logo文字@3x.png" alt="">
            </div>

            <div style="font-size: 18px;color: #fff;">火星1号基地动态</div>
        </div>
        <!-- <small-topview> </small-topview> -->
        <!-- <top-view  ref="topviews"  v-if="!showSmall && !topHidden" @changeLang="changeLang"
          @selectTable="selectTable" @menu="userMenuHandler"></top-view> -->
      </div>
     
      <div style="width: 100%; min-height: 800px; overflow-y: hidden; display: flex;justify-content: center; margin-top: 30px;">
        <div style="width: 1200px;padding: 40px 0px; background-color: aliceblue;">
            <div class="message-item" v-for="(item, index) in darftList" :key="index">
            <div class="message-item-left">
            
                <div class="message-item-left-num">{{item.update_time.substring(8,10)}}</div>
                <div class="message-item-left-text">{{item.update_time.substring(0,7)}}</div>
                <!-- <div class="message-item-left-line"></div> -->
            </div>
            <div class="message-item-right">
                <div class="message-item-right-title"  @click="tomessageDetail(item)">{{ item.title }}</div>
                <div class="message-item-right-time"> {{item.update_time}}</div>
                <div v-if="item.isExpand" class="message-item-right-message"
                style="display: flex;flex-direction: column;align-items: center;"
                v-html="$options.filters.formatRichText(item.content)">
                </div>
                <button class="message-item-right-all" style="border: none;"
                @click="tomessageDetail(item)">{{item.isExpand?'收起':'展开全部'}}</button>
                <div class="message-item-right-line"></div>
            </div>

            </div>
            <div v-if="darftList[0]" style="width: 100%;height: 30px; display: flex;position: relative;align-items: center;margin-top: 10px;">
                <div style="position: absolute;left: 40px;" @click="leftHandler()">{{ '早期动态' }}</div>
                <div style="position: absolute;right: 40px;" @click="rightHandler()">{{ '近期动态' }}</div>
            </div>
            <div v-else style="width: 100%;display: flex;margin-top: 100px; justify-content: center;">
                        <el-empty description="暂无数据"></el-empty>
                    </div>

        </div>

    </div>
      
      <div class="bline">
        <div class="bline-c">北京华声志海文化传播有限公司@2022</div>
        <a class="bline-c" style="margin-left: 16px;" href="https://beian.miit.gov.cn/">京ICP备2022016391号-4</a>
      </div>
  
     
    
    </div>
    <el-backtop  :right="100" :bottom="100" style="background-color: rgba(0, 0, 0, 0.01);" >
         <img ondragstart="return false" style="width: 50px; background-color: rgba(0, 0, 0, 0.01);" src="../assets/home/top.png" alt="">
          </el-backtop>
    </div>
    
  </template>
  
  <script>
  // @ is an alias to /src
  
  import * as API_URL from '../api/url'
//   import topView from './components/homeTop.vue'
//   import smallTopview from './components/smallScreenTopview.vue'
//   import debounce from '@/libs/debounce'
  
  export default {
    name: 'Home',
    components: {
    //   topView,
    //   smallTopview,
    },
    data() {
      return {
        showSmall: false,
        screenWidth: 0,
        lang: false,
        topHidden: false,
        bgimgArr: ['背景_01.jpg', '背景_02.jpg', '背景_03.jpg', '背景_04.jpg', '背景_05.jpg'],
        userData:null,
        darftList: [],
      searchFrom: {
        pageNum: 1,
        pageSize: 5,
        current:1,
        pages:1
      }
      }
    },

    filters: {
      formatRichText(html) {
      if (html) {
        let cont = html.replace(/data-src/g, 'src')
        let cont1 = cont.replace(/<p style="/g, '<p style="display: flex;flex-direction: column;align-items: center;')
        let cont2 = cont1.replace(/background-image/g,'' )
        // let cont2 = cont.replace(/<p style="/g, '<p style="display: flex;flex-direction: column;align-items: center;')
        return cont2
      }
    }
  },
    methods: {
      messageClick() {
        this.$message.error('点击');
      },

      leftHandler() {
      if (this.searchFrom.pageNum != 1) {
        this.searchFrom.pageNum--
        this.getWechatOffcial()
      }else{
        this.$message.warning('没有更多了！')
      }
      
    },
    rightHandler() {
      if (this.searchFrom.pageNum < this.searchFrom.pages) {
        this.searchFrom.pageNum++
        this.getWechatOffcial()
      }else{
        this.$message.warning('已经到底了！')
      }
     
    },
    // 查看公司动态
    tomessageDetail(e) {
      e.isExpand = !e.isExpand
    },

    getWechatOffcial() {

        API_URL.getWechatOffcial(this.searchFrom).then((res) => {
        if (res.code == 0) {
            this.darftList = res.data.records
            this.searchFrom.pages = res.data.pages
        }
        });
    },

  
      // 切换标签
      selectTable(e) {
        console.log(e)
        var anchor;
        if (e == 0) {
          // anchor = document.getElementById('top')
          anchor = this.$refs.top
        }
        if (e == 1) {
          // anchor = document.getElementById('js')
          anchor = this.$refs.js
        } else if (e == 2) {
          // anchor = document.getElementById('lc')
          anchor = this.$refs.lc
        } else if (e == 3) {
          // anchor = document.getElementById('zr')
          anchor = this.$refs.zr
        } else if (e == 4) {
          // anchor = document.getElementById('xz')
          anchor = this.$refs.xz
        } else if (e == 5) {
          // anchor = document.getElementById('hb')
          anchor = this.$refs.hb
        }
  
        anchor.scrollIntoView({ behavior: "smooth" });
  
      },
  
  
        // const codeobj = this.$route.query
        // console.log('ntfscan',this.$route.query)
        // if (codeobj.code) {
          // console.log('codeobj', codeobj.code)
          // this.testCode(codeobj.code)
  
        // 
        jumptoMars(){
            window.open('https://www.marsbase1.com','')
        }
  
    },
  
    mounted() {
  
     
  
      this.userData = localStorage.getItem('userData')
      this.getWechatOffcial()
      // this.initScroll()
      // this.testCode('081JxgGa1uolvF0tTvGa17EUWJ1JxgGu')
      
  
    },
  
    watch: {
      screenWidth: function (n) {
        if (n <= 950) {
          if (!this.showSmall) {
            this.showSmall = true
          }
        } else {
          if (this.showSmall) {
            this.showSmall = false
          }
        }
      }
    }
  
  }
  
  
    // mounted() {
    
  
  
    // }
  </script>
  
  <style lang="scss" scoped>
  @import url('./css/home.css');
  
  .home {
    background-color: black;
    box-sizing: border-box;
    overflow-x: hidden;
    height: fit-content;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('../assets/coreate/背景.jpg');
    background-size: cover;
  }
  
  .topv {
    // position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    box-sizing: border-box;
  }
  
  .toupiao {
    // z-index: 9999;
    position: fixed;
    right: calc(100vw * (40 / 1920));
    top: calc(100vw * (200 / 1920));
    width: calc(100vw * (212 / 1920));
    height: calc(100vw * (252 / 1920));
    font-size: 20px;
    border-radius: 12px;
    background-image: url('../assets/home/侧边-投票.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  
  }
  .toupiaotext{
    margin-top: calc(100vw * (100 / 1920));
    font-weight: bold;
    font-size: calc(100vw * (20 / 1920));
    color: #333;
    margin-right: calc(100vw * (12 / 1920));
    margin-right: calc(100vw * (12 / 1920));
  }
  
  .toupiaobtn{
    margin-top: calc(100vw * (17 / 1920));
    margin-right: calc(100vw * (12 / 1920));
    font-weight: bold;
    font-size: calc(100vw * (32 / 1920));
    width: calc(100vw * (170 / 1920));;
    height: calc(100vw * (54 / 1920));;
    color:#fff;
    background-image: url('../assets/home/按钮.png');
    background-size: cover;
    line-height: calc(100vw * (54 / 1920));;
  }
  
  .canshai {
    position: fixed;
    right: calc(100vw * (40 / 1920));
    top: calc(100vw * (500 / 1920));
    width: calc(100vw * (212 / 1920));
    height: calc(100vw * (252 / 1920));
    background-image: url('../assets/home/侧边-参赛.png');
    background-size: cover;
    font-size: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  
  
  .bgimgv {
    width: 100%;
    position: relative;
    top: 0px;
    left: 0px;
  
  }
  
  .bgimg {
    width: 100%;
  }
  
  .centerview {
    position: relative;
    top: 90px;
    left: 0px;
    width: 100%;
    // height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }
  
  #centerview::-webkit-scrollbar {
    display: none;
  }
  
  .ctitle {
    margin-top: calc(100vw * (220 / 1920));
    width: calc(100vw * (1191 / 1920));
  }
  
  .upbtn {
    margin-top: calc(100vw * (160 / 1920));
    width: calc(100vw * (486 / 1920));
  }
  
  
  .platemargintop {
    margin-top: calc(100vw * (216 / 1920));
    min-width: 1200px;
  }
  
  .platemargintop2 {
    margin-top: calc(100vw * (150 / 1920));
    min-width: 1200px;
  }
  
  
  .plate {
    width: calc(100vw * (1200/ 1920));
  }
  
  .bline {
    height: 93px;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
  }
  
  .bline-c {
    color: #fff;
    height: 30px;
    line-height: 30px;
    font-size: calc(100vw * (16 / 1920));
  }
  
  
  .bottom-up{
    height: 100%;
          width: 100%;
          background-color: #f2f5f6;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          line-height: 40px;
          color: #1989fa;
  }
  
  
  .cusz{
    background-image: url('../assets/home/左箭头.png');
    left: 0px;
  }
  .cusy{
    background-image: url('../assets/home/右箭头.png');
    right: 0px;
  }
  
  .chickable{
    // background-color: #e4765e;
  }
  
  .chickable ::v-deep .swiper-pagination-bullet{
    background: #fff;
    width: 13px;
    height: 13px;
  }
  
  .chickable ::v-deep .swiper-pagination-bullet-active{
    background: #e45700;
    width: 13px;
    height: 13px;
  }


.message-item {
  padding: 20px;
  display: flex;
  text-align: left;
}

.message-item-left {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-item-left-line {
  width: 50px;
  height: 3px;
  background-color: rgb(42, 42, 216);
}

.message-item-left-num {
  margin-top: 5px;
  font-size: 48px;
  color: #666;
}

.message-item-left-text {
  margin-top: 5px;
  font-size: 16px;
  color: #666;
}

.message-item-right {
  flex: 1;
  margin-left: 40px;
}

.message-item-right-title {
  color: #666;
  font-size: 20px;
  overflow: hidden;
}

.message-item-right-time {
  margin-top: 5px;
  color: #666;
  font-size: 12px;
  overflow: hidden;
}

.message-item-right-message {
  margin-top: 30px;
  width: 90%;
  font-size: 14px;
}

.message-item-right-all {
  margin-top: 15px;
  font-size: 14px;
  color: #333;
}

.message-item-right-line {
  border: 0;
  border-bottom: 1px dashed #7994b6;
  margin: 40px 0 24px 0;
  width: 590px;
}

.topview-logo1 {
        width: 30px;
        height: 38px;
    }

    .topview-logoText1 {
        margin-left: 12px;
        font-family: 'ZDY';
        font-size: 22px;
        color: #fff;
    }
  
  </style>
  