<template>
    <div class="art" >
      <div id="top" class="topv">
          <top-view  ref="toptitle"   @changeLang="changeLang"
        @selectTable="selectTable" @menu="userMenuHandler"></top-view>
      </div>

      <img ondragstart="return false" style="position: absolute;top: 82px;left: 0px;width: 100%;" src="../assets/art/背景_01.jpg" alt="">
      <div class="contv" style="">
        <div class="" style="width: 1280px;margin-top: 224px;position: relative;">
          <artcontent ref="artc"  @search="searchHandler" @page="pageChange"
           @itemClick="itemClick" :list="list" :total="total"
           @toupiao="voteHandler" :totalPages="totalPages" ></artcontent>
        </div>
      </div>
  

      <div class="toupiao" >
        <div class="toupiaotext">{{$t('homePage.jrkt') +' '+ voteNum}}/3</div>
        <div class="toupiaobtn">{{$t('homePage.tp') }}</div>
      </div>
      <div class="canshai" @click="jumptojoin()">
        <div class="toupiaotext">{{$t('homePage.sczp') }}</div>
        <div class="toupiaobtn">{{$t('homePage.sc') }}</div>
      </div>

      <el-backtop  :right="100" :bottom="100" style="background-color: rgba(0, 0, 0, 0.01);">
       <img ondragstart="return false" style="width: 50px;" src="../assets/home/top.png" alt="">
		</el-backtop>
   </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  import * as API_URL from '../api/url'
  import topView from './components/homeTop.vue'
  import debounce from '@/libs/debounce'
  import artcontent from './components/artcontent.vue'
  export default {
    name: 'art',
    components: {
      topView,
      artcontent
    },
    data() {
      return {
        showSmall: false,
        screenWidth: 0,
        lang: 'zh',
        topHidden: false,
        list:[],
        searchForm:{
          pageNum:1,
          pageSize:20,
          orderBy:1, //1时间 2热度
          categoryId:'',
          name:'',
          userId:'',
        },
        total:0,
        totalPages:0,
        voteNum:3, //投票次数
      }
    },
    methods: {
      messageClick() {
        this.$message.error('点击');
      },

      getList() {
        API_URL.list(this.searchForm).then((res) => {
              if (res.code == 0) { 
                this.list = res.data.records
                this.total = res.data.total
                this.totalPages = res.data.pages
                
              }
          });
      },
  
      selectTable(e) {
        console.log(e)
        this.$router.push({
          name:'Home',
          params:{
            index:e
          }
        })
      },

      searchHandler(e) {
        this.searchForm.userId = ''
        if(e.type==1){
          this.searchForm.name = e.value
        }else if(e.type==2){
          this.searchForm.categoryId = e.value
        }else if(e.type ==3){
          this.searchForm.orderBy = e.value
        }
          
        this.getList()
      },
  
      changeLang() {
        let isZh = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh';
        if (isZh === 'zh') {
          localStorage.setItem('lang', 'en'); // en表示英文，zh表示中文，可根据自己喜好设定，尽量通俗易懂
          this.$i18n.locale = 'en'
          this.lang = 'en'
        } else {
          localStorage.setItem('lang', 'zh');
          this.$i18n.locale = 'zh'
          this.lang = 'zh'
        }
  
        // location.replace(location); //刷新网页
        // this.$router.replace('/');
        // this.$router.go(0);
        window.location.reload();
      },
  
  
      viewScroll() {
        let current =
          document.documentElement.scrollTop || document.body.scrollTop;
        let vh = window.innerHeight;
        console.log(current, vh)
        if (current > 120) {
          if (!this.topHidden) {
            this.topHidden = true
          }
        }
  
        if (current < 120) {
          if (this.topHidden) {
            this.topHidden = false
            this.$refs.toptitle.selectIndex = 0
          }
        }
      },
  
      resetScrennwidth: debounce(function () {
        this.screenWidth = document.body.clientWidth
      }, 300),


      // 切换页面
      pageChange(page){
        this.searchForm.pageNum=page
        this.getList()
      },

      // 点击作品
      itemClick(item) {
        console.log(item.worksId)
        window.open('/#/artDetail?id='+item.worksId+'&status=0')
        // this.$router.push('./artDetail')
      },
      // 获取投票次数
      getVoteNum() {
        API_URL.voteNum().then((res) => {
              if (res.code == 0) { 
                this.voteNum = 3 - Number(res.data)
              }else{
                this.$message.info(res.message)
              }
          });
      },

      // 投票
      voteHandler(e){
        API_URL.vote({worksId:e.worksId}).then((res) => {
              if (res.code == 0) { 
                this.$message.success(this.$i18n.t('hint.tpcg'))
                this.getList()
                this.getVoteNum()
              }else{
                this.$message.info(res.message)
              }
          });
      },

      // 刷新投票数据
      reloadVoteData(){

      },

      jumptojoin() {
      this.$router.push('/join')
    },

    userMenuHandler(e){
      console.log(e)
      if(e<6){
        this.$router.push(
       {
        name :'user',
        params : {
          index:e
        }
       }
      )
      }else{
        localStorage.setItem('userData','')
        localStorage.setItem('accessToken','')
        this.$router.push({
          name:'Home',
        })
      }
     
    },
  
    },
  
    mounted() {
      //获取屏幕尺寸
      this.screenWidth = document.body.clientWidth
      window.onresize = () => {
        console.log('高度');
        //屏幕尺寸变化
        return (() => {
          this.resetScrennwidth()
        })()
      }
  
      window.addEventListener('scroll', this.viewScroll, true)
      this.getVoteNum()
     
      if(this.$route.params.name){
        this.searchForm.name = this.$route.params.name
        this.searchForm.userId = this.$route.params.userId
        this.$refs.artc.searchNameValue( this.searchForm.name)
      }
      this.getList()
    },
  
    watch: {
      screenWidth: function (n, o) {
        console.log(o)
        if (n <= 950) {
          console.log('屏幕宽度小于400了')
          if (!this.showSmall) {
            this.showSmall = true
          }
  
        } else {
          if (this.showSmall) {
            this.showSmall = false
          }
          console.log('屏幕宽度没有小于1200')
        }
      }
    }
  
  }
  
  
    // mounted() {
    // API_URL.delAfterSaleReason(1).then((res) => {
    //             if (res.success) {
    //                 console.log(res)
    //             }
    //         });
  
  
    // }
  </script>
  
  <style lang="scss" scoped>
  @import url('./css/art.css');

  .art {
    // background-color: black;
    box-sizing: border-box;
    overflow-x: hidden;
    height: fit-content;
    background-color: black;
    position: relative;
    width: 100%;
  }
  
  
  .topv {
    // position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    box-sizing: border-box;
  }

  .toupiao {
  position: fixed;
  right: calc(100vw * (40 / 1920));
  top: calc(100vw * (200 / 1920));
  width: calc(100vw * (212 / 1920));
  height: calc(100vw * (252 / 1920));
  font-size: 20px;
  border-radius: 12px;
  background-image: url('../assets/home/侧边-投票.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}
.toupiaotext{
  margin-top: calc(100vw * (100 / 1920));
  font-weight: bold;
  font-size: calc(100vw * (20 / 1920));
  color: #333;
  margin-right: calc(100vw * (12 / 1920));
}

.toupiaobtn{
  margin-top: calc(100vw * (17 / 1920));
  margin-right: calc(100vw * (12 / 1920));
  font-weight: bold;
  font-size: calc(100vw * (32 / 1920));
  width: calc(100vw * (170 / 1920));;
  height: calc(100vw * (54 / 1920));;
  color:#fff;
  background-image: url('../assets/home/按钮.png');
  background-size: cover;
  line-height: calc(100vw * (54 / 1920));;
}

.canshai {
  position: fixed;
  right: calc(100vw * (40 / 1920));
  top: calc(100vw * (500 / 1920));
  width: calc(100vw * (212 / 1920));
  height: calc(100vw * (252 / 1920));
  background-image: url('../assets/home/侧边-参赛.png');
  background-size: cover;
  font-size: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.contv{
  width: 100%;display: flex;justify-content: center;
}

.bottom-up{
  height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
}
  
  </style>
  