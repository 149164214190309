<template>
    <div style="margin-bottom:120px ;">

        <img ondragstart="return false" style="width: 1280px;position: relative;" src="../../assets/art/别表头.png" alt="">
        <div style="position: absolute; top: 15px;left: 39px;font-size: 24px;font-weight: bold;color: #E45700;">
            {{ $t('tableHeader.cszp') }}</div>
        <div
            style="display: flex; min-height: 700px; overflow: hidden; margin-top: -6px;background-color: #D8D8D8;padding: 30px 20px 30px 30px;">
            <div class="menu" >
                <div class="menu_seacrh" >
                    <el-input class="customv2" @change="searchHandler" clearable v-model="searchName"
                        :placeholder="searchP"></el-input>
                    <img ondragstart="return false" class="menu_seacrh_btn" @click.stop="toSearch()" src="../../assets/art/搜索.png" alt="">
                </div>
                <el-menu background-color="#fff" text-color="#333333" active-text-color="#E45700"
                    :default-active="activeIndex" class="el-menu-demo" @select="handleSelect">
                    <el-menu-item class="menu-cu" :index="item.id" v-for="(item, index) in menuList" :key="index">{{ item.value
                    }}</el-menu-item>
                </el-menu>
            </div>
            <div class="listview" >
                <div class="listview-topview">
                    <div class="listview-topview-btn " :class="{ tselect: tag == 1 }" @click="tagSelect(1)">
                        {{ $t('tableHeader.zxzp') }}</div>
                    <div style="width: 1px;height: 14px;background-color: #999999;margin-right: 10px;"></div>
                    <div class="listview-topview-btn" :class="{ tselect: tag == 2 }" @click="tagSelect(2)">
                        {{ $t('tableHeader.rmzp') }}</div>
                    <div style="flex: 1;"></div>
                    <div style="text-align: left; font-size: 14px;color: #6666666; max-width: 700px;">{{ $t('tableHeader.ts') }}</div>
                </div>
                <div class="listview-list" style="min-height: 600px;">
                    <div class="listview-list-item" v-for="(item, index) in list" :key="index" @click="itemClick(item)">
                        <img ondragstart="return false" class="listview-list-item-img" :src="item.coverImg" alt="">
                        <div class="listview-list-item-title">{{ item.worksName }}</div>
                        <div class="listview-list-item-type">{{ item.categoryName }}</div>
                        <div class="listview-list-item-nameview">
                            <img ondragstart="return false" class="listview-list-item-nameview-img" :src="item.authorHeadImg" alt="">
                            <div class="listview-list-item-nameview-text">{{ item.authorName }}</div>
                        </div>
                        <div class="listview-list-item-shadow">
                            <img ondragstart="return false" class="listview-list-item-shadow-img" src="../../assets/art/投票.png" alt="">
                            <div class="listview-list-item-shadow-text">{{ item.ticketNum }}</div>
                            <div v-if="item.vote" class="listview-list-item-shadow-btn1" @click.stop="toupiao(item)">
                                    {{$t('base.tp') }}
                            </div>
                            <div v-else class="listview-list-item-shadow-btn1" style="background-color: #999999;">
                                {{$t('base.ytp') }}
                            </div>
                            <!-- <img ondragstart="return false" 
                                src="../../assets/art/投票按钮.png" alt="">
                            <img ondragstart="return false" v-else class="listview-list-item-shadow-btn1" @click.stop=""
                                src="../../assets/art/已投票按钮.png" alt=""> -->
                        </div>
                    </div>

                    <div v-if="!list[0]" style="width: 100%;display: flex;margin-top: 100px; justify-content: center;">
                        <el-empty description="暂无数据"></el-empty>
                    </div>
                </div>
                <div style="width: 100%;margin-top: 20px;">
                    <el-pagination :background="true"  layout="prev, pager, next" :total="total" :hide-on-single-page="true" :page-size="20" @current-change="handleCurrentChange">
                    </el-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: '0',
            menuList: [{ value: this.$i18n.t('categraylm.qb'), id: '0' },
            { value: this.$i18n.t('categraylm.jz'), id: '1' },
            { value: this.$i18n.t('categraylm.pm'), id: '2' },
            { value: this.$i18n.t('categraylm.gy'), id: '3' },
            { value: this.$i18n.t('categraylm.sz'), id: '4' },
            { value: this.$i18n.t('categraylm.ty'), id: '5' },
            { value: this.$i18n.t('categraylm.gg'), id: '6' },
            ],
            pageNum: 1,
            tag: 1,
            searchName: '',
            searchP: this.$i18n.t('tableHeader.search')

        }
    },
    props: {
        total: Number,
        totalPages: Number,
        list: Array
    },
    methods: {
        tagSelect(e) {
            if (this.tag != e) {
                this.tag = e
                this.$emit('search', { type: 3, value: this.tag })
            }

        },
        handleSelect(key) {
            // this.$emit('menu', key)

            this.$emit('search', { type: 2, value: key == '0' ? '' : key })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.$emit('page', val)
        },
        itemClick(item) {
            this.$emit('itemClick', item)
        },
        toupiao(item) {
            this.$emit('toupiao', item)
        },

        searchHandler(e) {
            console.log('searchHandler', e)
            this.searchName = e
            this.$emit('search', { type: 1, value: e })
        },

        toSearch() {
            console.log('toSearch', this.searchName)
            this.$emit('search', { type: 1, value: this.searchName })
        },

        searchNameValue(e) {
            this.searchName = e
        },

        currentChangeHandler(e){
            this.$emit('pageChange',e)
            console.log('currentChangeHandler',e)
        }
    }
}
</script>

<style  scoped>
@import url('../css/art.css');

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #E45700;
    color: #FFF;
}


::v-deep .cust .el-input__inner {
    flex: 1;
    font-size: 14px;
    color: #333;
    border: none;
    outline: medium;
}


::v-deep .customv2 .el-input__inner {
    background-color: #F2F2F2 !important;
    color: #999999 !important;
    border: none !important;
    /* height: 25px; */
    align-items: center;
}

.menu-cu {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /* word-break: break-all; */
    white-space: pre-wrap;
    height: auto;
    line-height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 95%;
    left: 2.5%;
}</style>